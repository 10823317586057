<template>
    <b-card class="mb-4">
        <h3 class="fs-4 font-weight-bold mb-0">Vindbaarheid & marketing</h3>
        <p class="text-muted">Hoe moet het event gevonden worden?</p>
       
        <b-row>
            <b-col md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Hoofdcategorie</label>
                        <b-form-select @change="form.secondary_category = 0" v-model="form.primary_category" :options="getPrimary" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
            <b-col md="6">
                <div class="mb-3">
                    <label>Subcategorie</label>
                    <b-form-select v-model="form.secondary_category" :options="getSecondary" :disabled="!form.primary_category || getSecondary.length <= 1" />
                </div>
            </b-col>
        </b-row>

        <div class="mb-3">
            <label class="mb-0">Tags</label>
            <p class="text-muted mb-2">Optimaliseer de vindbaarheid van uw evenement door gebruik te maken van tags.</p>
            <b-form-tags
                v-model="form.tags"
                tag-variant="primary"
                tag-pills
                size="md"
                separator=" "
                placeholder="Voer nieuwe tags in, gescheiden door spaties"
                remove-on-delete
            />
        </div>

        <div class="mb-3">
            <label>Uitgelicht evenement</label>
            <b-form-checkbox v-model="form.is_highlighted" class="pl-1">
                Zet dit evenement in de schijnwerpers en geef het extra aandacht.
            </b-form-checkbox>
        </div>
    </b-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider
    },
    props: {
        form: Object,
    },
    data() {
        return {
            categories: [],
            tags: [],
        }
    },
    computed: {
        getPrimary() {
            let options = [];
            this.categories.forEach( function ( value ) {
                if (!value.parent_id) {
                    options.push({
                        text: value.name,
                        value: value.id,
                    })
                }
            });
            return options;
        },
        getSecondary() {
            let options = [];

            options.push({
                text: '---',
                value: 0,
            });

            let current_category = this.form.primary_category;
            this.categories.forEach( function ( value ) {
                if (value.parent_id == current_category) {
                    options.push({
                        text: value.name,
                        value: value.id,
                    })
                }
            });
            return options;
        }
    },
    methods: {
        getCategories: function() {
            this.$axios.get("https://api.tixgo.nl/eventscategories")
            .then( response => {
                if (response.data.status == 'success') {
                    this.categories = response.data.data;
                }
            });
        },
        getTags: function() {
            this.$axios.get("https://api.tixgo.nl/events")
            .then( response => {
                if (response.data.status == 'success') {
                    this.tags = response.data.data;
                }
            });
        }
    },
    mounted() {
        this.getCategories();
        this.getTags();
    },
}
</script>