<template>
    <b-card class="mb-4">
        <h3 class="fs-4 font-weight-bold mb-0">Start verkoop</h3>
        <p class="text-muted">Vanaf wanneer mogen er tickets besteld worden?</p>
        <div class="mb-3">
            <label class="required">Startdatum verkoop</label>
            <b-row>
                <b-col cols="8">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <p class="text-muted mb-0">Datum</p>
                        <b-form-input v-model="form.start_sale_at_date" type="date" max="9999-12-31" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <p class="text-muted mb-0">Tijd</p>
                        <b-form-input v-model="form.start_sale_at_time" type="time" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </div>
        <div class="mb-3">
            <label class="required">Einddatum verkoop</label>
            <b-row>
                <b-col cols="8">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="mb-3">
                            <p class="text-muted mb-0">Datum</p>
                            <b-form-input v-model="form.end_sale_at_date" type="date" max="9999-12-31" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="mb-3">
                            <p class="text-muted mb-0">Tijd</p>
                            <b-form-input v-model="form.end_sale_at_time" type="time" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },
        props: {
            form: Object,
        },
        computed: {},
        methods: {},
        created() {}
    }
    </script>