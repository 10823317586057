<template>
    <div>
        <b-card class="mb-4">
            <h3 class="fs-4 font-weight-bold mb-0">Soort evenement</h3>
            <p class="text-muted">Wat voor soort evenement is het?</p>
            <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                <label class="required">Type evenement</label>
                <b-form-radio-group class="type-toggle" v-model="form.type" :options="typeOptions" stacked />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </b-card>

        <b-card class="mb-4">
            <h3 class="fs-4 font-weight-bold mb-0">Datum & tijd</h3>
            <p class="text-muted">Wanneer vindt het evenement plaats?</p>
            <b-alert v-if="form.published_at && !duplicate" class="shadow" variant="warning" show>
                <b-row align-v="center">
                    <b-col class="text-center text-md-right order-md-1" md="2">
                        <font-awesome-icon class="mb-1 mb-md-0" icon="exclamation-triangle" size="2x" />
                    </b-col>
                    <b-col class="text-center text-md-left">
                        <h5 class="font-weight-bold mb-0">Dit evenement is reeds gepubliceerd</h5>
                        <div>Schema wijzigingen aan dit evenement worden automatisch aan bezoekers gecommuniceerd.</div>
                    </b-col>
                </b-row>
            </b-alert>
            <b-card v-for="(slot, key) in form.slots" :key="key" class="mb-3">
                <template v-if="!form.canceled_at">
                    <div class="remove-slot">
                        <b-button v-if="form.slots.length > 1" @click="startDelete(slot)" variant="outline-primary" class="float-right">
                            <font-awesome-icon icon="fa-solid fa-remove ml-2" />
                        </b-button>
                    </div>
                </template>
                <h4 class="fs-5 mb-0 font-weight-bold">Tijdslot {{ key + 1 }}</h4>
                <div class="mb-3">
                    <label class="required">Startdatum</label>
                    <b-row>
                        <b-col cols="8">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                            <p class="text-muted mb-0">Datum</p>
                            <b-form-input v-model="slot.start_at_date" type="date" max="9999-12-31" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                            <p class="text-muted mb-0">Tijd</p>
                            <b-form-input v-model="slot.start_at_time" type="time" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        </b-col>
                    </b-row>
                </div>
                <div class="mb-3">
                    <label class="required">Einddatum</label>
                    <b-row>
                        <b-col cols="8">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                            <p class="text-muted mb-0">Datum</p>
                            <b-form-input v-model="slot.end_at_date" type="date" max="9999-12-31" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                            <p class="text-muted mb-0">Tijd</p>
                            <b-form-input v-model="slot.end_at_time" type="time" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
            <template v-if="!form.canceled_at">
                <template v-if="form.type == 'multiple'">
                    <b-button @click="addSlot" variant="primary">
                        Datum toevoegen
                        <font-awesome-icon icon="fa-solid fa-plus ml-2" />
                    </b-button>
                </template>
            </template>
        </b-card>
        <b-modal id="cancel-slot-modal" title="Tijdslot annuleren" hide-header-close>
            <b-alert variant="warning" show>
                Je staat op het punt dit tijdslot te annuleren. Dit is een <strong>permanente actie</strong> en kan <strong>niet meer ongedaan worden gemaakt</strong>.
            </b-alert>
            <div class="mb-3">
                <label>Reden voor wijziging <small>(optioneel)</small></label>
                <VueTrix v-model="form.cancel_message" />
                <p class="text-muted">Dit bericht is zichtbaar voor bezoekers en wordt toegevoegd aan ons annuleringsbericht.</p>
            </div>
            <template #modal-footer="{ close }">
                <b-button variant="outline-secondary" @click="close()">Annuleren</b-button>
                <b-button variant="danger" @click="removeSlot(modal_delete_obj);">Definitief annuleren</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import VueTrix from "vue-trix"

export default {
    props: {
        form: Object,
        duplicate: {
            type: [Boolean],
            default: false,
        }
    },
    components: {
        ValidationProvider,
        VueTrix,
    },
    data() {
        return {
            modal_delete_obj: null,
            messages: {
                cancel_message: null
            },
            typeOptions: [
              { html: '<strong>Eenmalig evenement</strong><br/>Voor evenementen die eenmalig plaats vinden.', value: 'single' },
              { html: '<strong>Meervoudig evenement</strong><br/>Voor meervoudige evenementen zoals theater voorstellingen of een festival.', value: 'multiple' }
            ],
        }
    },
    computed: {},
    methods: {
        startDelete: function(slot) {
            this.modal_delete_obj = slot
            this.$bvModal.show('cancel-slot-modal');
        },
        removeSlot: function(slot) {
            // Slot exists in database, so we have to remove it.
            if (slot.id && !this.duplicate) {
                if( confirm("Weet je zeker dat je deze datum reeks wilt annuleren?") ) {
                    this.$axios.post("https://api.tixgo.nl/eventsdates/delete/" + slot.id, this.form)
                        .then( response => {
                            response;

                            var index = this.form.slots.indexOf(slot);
                            if (index !== -1) {
                                this.form.slots.splice(index, 1);
                            }

                            this.$bvModal.hide('cancel-slot-modal');
                            this.form.cancel_message = null;
                        })
                    ;
                }
            } else {
                var index = this.form.slots.indexOf(slot);
                if (index !== -1) {
                    this.form.slots.splice(index, 1);
                }
            }
        },
        addSlot: function() {
          this.form.slots.push({
            start_at_date: null,
            start_at_time: null,
            end_at_date: null,
            end_at_time: null,
          });
        },
    },
    created() {
        this.addSlot();
    },
}
</script>

<style>
  .type-toggle label{
    margin-left:10px;
    vertical-align: middle;
  }
  .type-toggle .custom-radio{
    border:1px solid #eee;
    border-radius:0.5rem;
    margin-bottom:15px;
    padding:15px;
  }
  .remove-slot{
    position:absolute;
    right:15px;
    top:15px;
  }
  .tab-body{
    border:1px solid #eee;
  }
</style>