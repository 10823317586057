<template>
    <b-card class="mb-4">
        <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
                <label class="required">Naam evenement</label>
                <b-form-input v-model="form.name" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
                <label class="required">Beschrijving</label>
                <VueTrix v-model="form.description"/>
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>

        <div class="mb-3">
            <label>Afbeelding</label>
            <p class="text-muted">Upload een afbeelding, deze wordt getoond op de webshop en in de e-mails. Optimaal formaat: 1600x900px (max 5MB)</p>
            <DropFile v-model="form.header_image" accept="image/*" />
        </div>
    </b-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import DropFile from "@/components/DropFile.vue";
import VueTrix from "vue-trix";

export default {
    props: {
        form: Object,
    },
    components: {
        ValidationProvider,
        DropFile,
        VueTrix,
    },
    computed: {},
    methods: {},
    data() {
        return {
            category: []
        }
    },
    created() {},
}
</script>