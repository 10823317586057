<template>
    <b-card class="mb-4">
        <h3 class="fs-4 font-weight-bold mb-0">Extra opties</h3>
        <p class="text-muted">Minimale leeftijd en kosten.</p>
        <div class="mb-3">
            <label>Maximaal aantal tickets per bestelling</label>
            <b-form-input v-model="form.ticket_order_limit" v-mask="'#########'" placeholder="Geen" min="0" />
        </div>
        <div class="mb-3">
            <label>Minimale leeftijd</label>
            <b-form-input v-model="form.minimal_age" v-mask="'#########'" placeholder="Geen" min="0" />
        </div>
        <div class="mb-3">
            <label class="mb-0">Transactiekosten doorberekenen</label>
            <p class="text-muted mb-2">De kosten zijn afhankelijk van de gekozen betaalmethode.</p>
            <b-form-select v-model="form.is_customer_transaction_fee" :options="yesNoOptions" />
        </div>
        <div class="mb-3">
            <label class="mb-0">Servicekosten doorberekenen</label>
            <p class="text-muted mb-2">Bepaal zelf de kosten die klanten per ticket (extra) betalen.</p>
            <b-form-select v-model="form.is_customer_ticket_fee" :options="yesNoOptions" />
        </div>
        <template v-if="form.is_customer_ticket_fee">
            <div class="mb-3">
            <label class="mb-0">Servicekosten</label>
            <p class="text-muted mb-2">Extra kosten per ticket, dit staat los van de kosten aan ons.</p>
            <b-form-input v-money="money" v-model="form.customer_ticket_fee" min="0" placeholder="0.00" />
            </div>
        </template>
        <div class="mb-3">
            <label class="mb-0">Facturen genereren bij bestellingen</label>
            <p class="text-muted mb-2">Genereer facturen voor je klanten. De ticketprijzen zijn inclusief btw.</p>
            <b-form-select v-model="form.is_invoicing" :options="yesNoOptions" />
        </div>

        <template v-if="form.is_invoicing">
            <div class="mb-3">
            <label class="required">Btw-tarief</label>
            <b-form-radio-group class="type-toggle" v-model="form.invoicing_vat_rate" :options="vatRateOptions" stacked />
            </div>
        </template>

        <div class="mb-3">
            <label class="mb-0">Donaties aannemen</label>
            <p class="text-muted mb-2">Accepteer donaties voor je evenement.</p>
            <b-form-select v-model="form.is_accept_donations" :options="yesNoOptions" />
        </div>
        <template v-if="form.is_accept_donations">
            <div class="mb-3">
            <label>Donatie beschrijving</label>
            <b-form-textarea v-model="form.donation_description" />
            </div>
        </template>
    </b-card>
</template>

<script>
import { VMoney } from 'v-money'

export default {
    components: {},
    props: {
        form: Object,
    },
    directives: {
        money: VMoney
    },
    data() {
        return {
            money: {
              decimal: '.',
              thousands: '',
              prefix: '',
              suffix: '',
              precision: 2,
            },
            yesNoOptions: [
              { text: 'Ja', value: true },
              { text: 'Nee', value: false }, 
            ],
            vatRateOptions: [
              { html: '<strong>0% btw</strong><br/>Je mag een 0%-tarief hanteren wanneer je vrijstelling hebt gekregen van de Belastingdienst.', value: 0 },
              { html: '<strong>9% btw</strong><br/>Voor culturele of recreatieve evenementen zoals sportwedstrijden en podium-optredens.', value: 9 },
              { html: '<strong>21% btw</strong><br/>Voor zakelijke evenementen zoals seminars en congressen.', value: 21 },
            ],
        }
    },
    computed: {},
    methods: {},
    created() {},
}
</script>