<template>
    <b-card class="mb-4">
        <template v-if="!form.canceled_at">
            <b-button to="/manage/organisation/locations/create" variant="outline-primary" target="_blank" class="float-right">
                Locatie toevoegen
                <font-awesome-icon icon="fa-solid fa-plus ml-2" />
            </b-button>
        </template>
        <h3 class="fs-4 font-weight-bold mb-0">Locatiegegevens</h3>
        <p class="text-muted">Waar vindt het evenement plaats?</p>
        <div class="mb-3">
            <label>Naam locatie</label>
            <b-form-select v-model="form.location_id" :options="locationsOptions" />
        </div>
    </b-card>
</template>

<script>
export default {
    components: {},
    props: {
        form: Object,
    },
    data() {
        return {
            locations: [],
        }
    },
    computed: {
        locationsOptions() {
            let obj = [];
            obj.push({ value: null, text: 'Geen' });
            this.locations.forEach(
                item => {
                    obj.push({ value: item.id, text: item.name });
                }
            );

            return obj;
        }
    },
    methods: {
        getLocations: function() {
            this.$axios.get("https://api.tixgo.nl/locations")
            .then( response => {
                this.locations = response.data;
            }).catch(response => { console.log(response); });
        }
    },
    created() {
        this.getLocations();
    }
}
</script>